import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SubpageHeader from '../components/SubpageHeader/SubpageHeader';
import SubpageBlocks from '../components/SubpageBlocks/SubpageBlocks';
import useSetLocale from '../hooks/useSetLocale';

const Personvern = ({ location, data }) => {
  const { headline, imageOrVideo, intro, body } = data.sanityPrivacy;
  useSetLocale();

  return (
    <Layout location={location}>
      <SubpageHeader
        headline={headline}
        intro={intro}
        media={imageOrVideo}
        location={location}
        hideBreadcrumbs={true}
      />
      <div className="page-padding">
        {body && <SubpageBlocks location={location} contents={body} />}
      </div>
    </Layout>
  );
};

export default Personvern;

export const query = graphql`
  query {
    sanityPrivacy {
      intro {
        nb
        en
        _type
        _key
      }
      imageOrVideo {
        ... on SanityVideoUpload {
          ...HdVideoQuery
        }
        ... on SanityMediaImage {
          ...MediaImageQuery
        }
      }
      headline {
        nb
        en
        _type
        _key
      }
      body: _rawArticleBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;
